@import 'bootstrap_setup'

.outputSection
  margin: 0.8rem 0

#runInputs
  .inputs
    padding: 0.8rem 1.6rem
    .inputRow
      border-bottom: 1px solid #ccc
      &:last-of-type
        border-bottom: none
      .field
        font-weight: 600
        padding: 0.4rem 0.6em
      .value
        font-size: 1.2rem
        padding: 0.4rem 0.6em
    .inputHead
      font-weight: 600
    .inputRow, .inputHead
      .value, .head
        border-left: 1px solid #ccc

#outputForm
  margin-bottom: 35px

.outputs
  h1
    font-size: 2.6rem
    border-bottom: 4px solid theme-color('primary')
    padding-top: 0.8rem
  h2
    font-size: 2rem
    margin: 3rem 0 0
    padding: 0.6rem 1.2rem
    color: #fff
    background: theme-color('primary')
    .svg-inline--fa
      float: right
      margin: 0.1em 0
  h3
    margin: 0.5rem 0 1rem
  .stickyWrap
    z-index: 1000
    #scrollSpy
      transition: left 0.6s
      width: 300px
      height: 100vh
      background-color: #fff
      border-right: 1px solid theme-color('primary')
      .nav.flex-column
        overflow: hidden
      &.hidden
        left: -300px
      &.show
        left: 2px
      .navOpenButton
        cursor: pointer
        width: 32px
        margin-right: -48px
        padding: 0.6rem 0.2rem
        background-color: #fff
        display: flex
        align-items: center
        justify-content: center
        border-radius: 0 0.5rem 0.5rem 0
        border-bottom: 1px solid theme-color('primary')
        border-right: 1px solid theme-color('primary')
        border-top: 1px solid theme-color('primary')
  table
    margin: 0 0 3rem
    tr.subrow
      > td
        font-size: 0.9rem
        &:first-child
          font-style: italic
          padding-left: 3rem


  canvas
    margin: 3rem 0

  .navbar
    position: fixed
    z-index: 100
    a
      color: $gray-600
      padding: 0.1rem 0
      font-size: 0.9rem
      &:hover
        color: $gray-700
      &.active
        color: theme-color('primary')
    .nav .nav
      padding-left: 0.8rem
    > .nav
      height: 100vh
      padding: 0.6rem 0

  .defCol
    width: 50%

@media (min-width: 1700px)
  .sticky
    &.hidden
      left: 2px
