@import "bootstrap_setup"

.Select.form-control
  padding: 0

  .Select-control
    border: none

    .Select-value
      padding-top: 2px

.input-help
  display: inline-block
  margin: 0 0.3rem
  .input-help-hover
    cursor: pointer
    font-size: 1.2rem

.proportion-forms
  .form-control
    color: $gray-600
    &.changed:not(.is-invalid)
      color: darken($green, 10%)
      border-color: darken($green, 10%)
      &:focus
        box-shadow: 0 0 0 0.2rem rgba(darken($green, 10%), .25) !important

  .form-row
    align-items: center
    padding: 0 0.3rem

    label, .form-group
      margin-bottom: 0

    .form-group
      padding: 0.6rem 0

  .form-row:nth-of-type(even)
    background-color: darken($gray-100, 3%)
  .form-row:nth-of-type(odd)
    background-color: $gray-100

form
  .rc-slider-rail
    background-color: #c7c7c7
  .rc-slider-dot
    width: 12px
    height: 12px
    margin-left: -6px
    bottom: -4px
    border-color: #c7c7c7
  .rc-slider-track
    background-color: theme-color('primary')
  .rc-slider-handle, .rc-slider-dot-active
    border-color: theme-color('primary')
  .rc-slider-mark
    font-size: 16px

#outputForm, #runInputs
  background: #eff1f4
  padding: 0
  margin-bottom: 0.6rem
  .timeHorizon
    padding: 1.4rem 2.5rem 1rem
#outputForm
  .row
    padding: 0.2rem 1.4rem 1rem

#riskFactorForm, #interventionForm
  .form-row
    .input-group
      justify-content: center
      input
        max-width: 10rem


#user_defined_intervention, #settings
  .input-group
    max-width: 100%
div.proportion-header.proportion-header.proportion-header
  background-color: transparent
