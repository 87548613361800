@import "bootstrap_setup"

body
  position: relative
#main-container
  padding-top: 2rem

.navbar
  a
    text-decoration: none

#footer
  margin-top: 5rem

.phe-logo
  border-left: 3px solid $white

  
.uos-logo
  width: 270px
