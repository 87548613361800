h1, h2, h3, h4, h5, h6
  -webkit-font-smoothing: antialiased

.container
  h1, .h1
    font-size: 2.2rem
  h2, .h2
    font-size: 1.8rem

body

  p
    line-height: 175%
    margin-bottom: 1.2rem
    color: #333

  li
    p
      margin-bottom: 0.2rem

  h2, h3, h4, h5, h6
    padding-top: 1.2rem

p a
  text-decoration: underline
