.navbar.navbar-expand-lg.navbar-dark.bg-primary
  padding: 0

  .nav-item
    .nav-link
      padding: 0.8rem

    &.active
      .nav-link
        border-bottom: 0.3rem solid #fff
        padding-bottom: 0.50rem

.navbar.navbar-expand-lg.navbar-dark
  padding: 0