$theme-colors: ( "primary": #822432 )
$colors: ( "blue": #00549f )
$colors: ( "pink": #dca9a5 )
$colors: ( "red": #822433 )
$colors: ( "orange": #e9994a )
$colors: ( "yellow": #eaab00 )
$colors: ( "green": #00a551 )
$colors: ( "teal": #00b092 )
$colors: ( "cyan": #70c9c0 )

$headings-margin-bottom: 1rem

$link-color: #00539F

$navbar-dark-color: rgba(#fff, .75)
$navbar-dark-hover-color: rgba(#fff, .95)

$breadcrumb-divider: quote(">")
